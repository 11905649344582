<template>
  <div class="container">
    <div class="c35a9fc52bb556c79f8fa540df587a2bf465b940">
      <h1>Not found <span>:(</span></h1>
      <p>Sorry, but the page you were trying to view does not exist.</p>

      <i>404</i>
    </div>
  </div>
</template>

<script>
export default {
  name: "error",
};
</script>

<style src="@/assets/css/404.css" />
